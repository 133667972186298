import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'gatsby-theme-psg/src/components/layout/Layout'
import Header from '../components/Header'
import Lead from '../components/Lead'
import EventContext from 'gatsby-theme-psg/src/components/EventContext';
import Img from 'gatsby-image'

export const TimetablePageTemplate = ({ title, content }) => {
  return (
    <React.Fragment>
      <Header background='rgba(255,120,0,0.85)'>
        <Lead title={title} content={content}/>
      </Header>
      <div className="container my-5 py-5">
        <EventContext.Consumer>
          {({ state }) => (
            state.event.frontmatter.timetable ?
                <Img fluid={state.event.frontmatter.timetable.childImageSharp.fluid}
                  alt="timetable"/>
              :
              <span className="lead text-dark">De timetable komt binnenkort online</span>
          )}
        </EventContext.Consumer>
      </div>
    </React.Fragment>
  )
}

const TimetablePage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout title={post.frontmatter.title} description={post.frontmatter.description}>
      <TimetablePageTemplate title={post.frontmatter.title} content={post.html} />
    </Layout>
  )
}

export default TimetablePage

export const pageQuery = graphql`
  query TimetablePage {
    markdownRemark(frontmatter: { templateKey: { eq: "timetable-page" } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
